import s from "./Footer.module.css";
import {UserOutlined, DollarOutlined, SkinOutlined, MoreOutlined, HomeOutlined} from "@ant-design/icons"
import { Modal } from "antd";

const Footer = ({setActiveWard, setactiveUpdates, activeWard, activeUpdates, setActiveProfile, activeProfile}) => {
    
  return <>
        <div className={s.nav}>
        
            <div className={s.icon} onClick={() => {
                setactiveUpdates(false);
                setActiveWard(false);
                setActiveProfile(false)}}>
                <HomeOutlined />
                <div>
                    Home
                    </div>               
            </div>
            <div className={s.icon} onClick={() => {
                setActiveWard(false)
                setActiveProfile(false)
                setactiveUpdates(!activeUpdates);
                }}>
                <DollarOutlined />
                <div>
                    Upgrades
                </div>
            </div>
            <div className={s.icon} onClick={() => {
                setactiveUpdates(false);
                setActiveProfile(false);
                setActiveWard(!activeWard)}}>
                <SkinOutlined />
                <div>
                    Wardrobe
                </div>
            </div>
            <div className={s.icon} onClick={() => {
                setactiveUpdates(false);
                setActiveWard(false);
                setActiveProfile(!activeProfile)}}>
                <UserOutlined />
                <div>
                    Profile
                </div>
            </div>
        </div>
  </>;
};


export default Footer;

