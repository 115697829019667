import s from "./Wardrobe.module.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Segmented } from "antd";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import {
  setWardrobeItem,
  setWardrobeUserData,
  setWardrobeItems,
} from "../../Redux/user/userReducer";

const setWardrobe = (id, value) => {
  return (dispatch, getState) => {
    dispatch(setWardrobeItem(value, id));
    const user = getState().user;
    axios
      .patch(`https://blogerverse.com/api/widerobe`, {
        user_id: user.id,
        eyes: user.eyes,
        iris: 2,
        hair: user.hair,
        mouth: user.mouth,
        dummy: user.dummy,
        body: user.body,
        legs: user.legs,
        shoes: user.shoes,
      })
      .then((res) => {
        dispatch(setWardrobeUserData(res.data.customisation));
        dispatch(setWardrobeItems(res.data.items));
      })
      .catch((error) => {
        console.error("Error updating wardrobe", error);
      });
  };
};

const Wardeobe = () => {
  const [value, setValue] = useState("hair");
  const [list, setList] = useState([]);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/widerobe?type=${value}`)
      .then((res) => {
        console.log(res.data);
        setList(res.data);
      });
  }, [value]);

  const handleUpdateWardrobe = ({ id }) => {
    dispatch(setWardrobe(id, value));
  };
  const preventImageDrag = (e) => {
    e.preventDefault();
};

  return (
    <div
      className={s.wardeobeBlock}
      style={{
        // backgroundColor: "#24242e",
        opacity: 1,
        width: "100vw",
        bottom: "100px",
        position: "absolute",
        zIndex: 10,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Segmented
        className={s.sideWiderobe}
        value={value}
        vertical
        onChange={setValue}
        options={[
          {
            value: "hair",
            icon: <AppstoreOutlined />,
          },
          {
            value: "eyes",
            icon: <BarsOutlined />,
          },
          // {
          //   value: "iris",
          //   icon: <AppstoreOutlined />,
          // },

          {
            value: "mouth",
            icon: <AppstoreOutlined />,
          },
          // {
          //   value: "dummy",
          //   icon: <AppstoreOutlined />,
          // },
          {
            value: "body",
            icon: <AppstoreOutlined />,
          },
          {
            value: "legs",
            icon: <AppstoreOutlined />,
          },
          {
            value: "shoes",
            icon: <AppstoreOutlined />,
          },
        ]}
      />
      <div className={s.infoBlock}>
        {list.map((el) => (
          <div
            className={
              el.id == user.hair ||
              el.id == user.eyes ||
              el.id == user.mouth ||
              el.id == user.body ||
              el.id == user.legs ||
              el.id == user.shoes
                ? s.itemCustomActive
                : s.itemCustom
            }
            style={{ width: "6oopx" }}
            onClick={() => handleUpdateWardrobe(el)}
          >
            <img
              style={{ height: "60vh" }}
              alt="item2" onDragStart={preventImageDrag}
              src={`https://blogerverse.com${el.img}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wardeobe;
