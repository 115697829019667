import s from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import axios from "axios";
import coin from "../../assets/Header/coin.png"
import {
  HeartOutlined,
  DollarOutlined,
  SkinOutlined,
  MoreOutlined,
  HeartFilled,
  DollarTwoTone,
  
} from "@ant-design/icons";

const Header = () => {
  const { lang } = useSelector((state) => state.lang);
  const [data, setData] = useState(null)
  const [score, setScore] = useState(0);
  const [animations, setAnimations] = useState([]);
  useEffect(() => {
    axios.get("https://blogerverse.com/api/balance?id=1").then(res => {
        
        setData(res.data[0])
        incrementScore()
    });
  }, []);
  const incrementScore = () => {
    setScore(score + 1);
    // Создаем анимационный элемент
    const newAnimation = (
      <span key={Date.now()} className={s.scoreAnimation}>+1</span>
    );
    setAnimations((prevAnimations) => [...prevAnimations, newAnimation]);

    // Удаляем анимацию после 1 секунды
    setTimeout(() => {
      setAnimations((prevAnimations) => prevAnimations.slice(1));
    }, 1000);
  };
  return (
    <header className={s.header}>
      <div className={s.score}>
        <Counter score={data?.like_count} animations={animations}/>
        <Counter name="money" score={data?.coin_count} />
      </div>
    </header>
  ); 

};


const Counter = ({ name = "likes", score, animations }) => {
  return (
    <div className={s.counter}>
      {name==="likes" ? <HeartFilled  style={{marginRight:"5px", color:"red"}} /> : <img style={{marginRight:"5px", height:"30px"}} src={coin}/>}
      <div className={s.scoreContainer}>
        <span className={s.score}>{score}</span>
        <div className={s.scoreAnimationContainer}>
          {animations}
        </div>
      </div>

    </div>
  );
};

export default Header;
