import s from "./Updates.module.css"; // Предположим, что у вас есть стили для выделения
import React, { useEffect, useState } from "react";
import { Avatar, List, Skeleton } from "antd";
import axios from "axios";
import { BarsOutlined } from "@ant-design/icons";

const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [userUpdates, setUserUpdates] = useState([]);
  const [loading, setLoading] = useState(true);

  // Получаем все улучшения
  useEffect(() => {
    axios
      .get("https://blogerverse.com/api/updates/")
      .then((response) => {
        setUpdates(response.data); // Установка данных о всех улучшениях
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching updates:", error);
        setLoading(false);
      });
  }, []);

  // Получаем улучшения, которые уже купил пользователь
  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/user-updates/${1}`)
      .then((response) => {
        setUserUpdates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user updates:", error);
      });
  }, []);

  // Функция, которая проверяет, было ли улучшение куплено пользователем
  const isUpdatePurchased = (updateId) => {
    return userUpdates.includes(updateId);
  };

  return (
    <div>

    <List
      style={{
        backgroundColor: "white",
        height: "600px",
        width: "100vw",
        bottom: "59px",
        position: "absolute",
        zIndex: 10,
        overflow: "scroll",
      }}
      className="demo-loadmore-list"
      loading={loading} // Загрузка
      itemLayout="horizontal"
      dataSource={updates} // Источник данных
      renderItem={(item) => (
        <List.Item
        actions={[<a key="list-loadmore-edit">Купить</a>]}
        style={{
          backgroundColor: isUpdatePurchased(item.id) ? "#d4edda" : "white", // Цвет для выполненных обновлений
          borderRadius: "5px", // Закругление углов
          }}
          >
          <Skeleton avatar title={false} loading={loading} active>
            <List.Item.Meta
              avatar={<Avatar icon={<BarsOutlined />} />}
              title={<a href="#">{item.name}</a>} // Ссылка на тип улучшения
              description={`Стоимость: ${item.cost_money || 0} денег, ${
                item.cost_coins || 0
              } монет, ${item.cost_likes || 0} лайков`} // Описание улучшения
              />
          </Skeleton>
        </List.Item>
      )
    }
    />
    </div>
  );
};

export default Updates;
