import s from "./Main.module.css";
import React, { useEffect, useState } from "react";
import { Avatar, Button, List, Skeleton } from "antd";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Provider, useDispatch, useSelector } from "react-redux";
import { setWardrobeItem, setWardrobeUserData , setWardrobeItems} from "../../Redux/user/userReducer";

export const MainPage = ({activeProfile, activeWard, activeUpdates}) => {
  return (
    <div className={s.main}>
      <div>
        {!activeProfile && !activeUpdates && !activeWard  && <MainBtn/>}
        <Person activeWard={activeWard} />
        {/* <MainBtn /> */}
      </div>
    </div>
  );
};

const Person = ({activeWard}) => {
  const [custom, setCustom] = useState([]);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get("https://blogerverse.com/api/usercustomlist?id=1").then((res) => {
      let customId = res.data.customisation;
      dispatch(setWardrobeUserData(customId));
      dispatch(setWardrobeItems(res.data.items));
    });
  }, []); 
  

  return<div style={{paddingTop:"10px"}}>
    <div className={s.leftIcons}>
      <AppstoreOutlined /> 
      <AppstoreOutlined /> 
    </div>
    <div className={s.person}>
      <img
        style={{
          zIndex: "1",
          opacity: `${activeWard ? 0.8 : 1}`,
        }}
        src={`https://blogerverse.com${user.dummyUrl}`}
        />
      <img
        style={{
          zIndex: "2",
          opacity: `${activeWard ? 0.8 : 1}`,
        }}
        src={`https://blogerverse.com${user.eyesUrl}`}
        />
      <img
        style={{
          zIndex: "4",
          opacity: `${activeWard ? 0.8 : 1}`,
        }}
        src={`https://blogerverse.com${user.hairUrl}`}
        />
      <img
        style={{
          zIndex: "3",
          opacity: `${activeWard ? 0.8 : 1}`,
        }}
        src={`https://blogerverse.com${user.bodyUrl}`}
        />
      <img
        style={{
          zIndex: "2",
          opacity: `${activeWard ? 0.8 : 1}`,
        }}
        src={`https://blogerverse.com${user.mouthUrl}`}
        />
      <img
        style={{
          zIndex: "5",
          opacity: `${activeWard ? 0.85 : 1}`,
        }}
        src={`https://blogerverse.com${user.legsUrl}`}
        />
      <img
        style={{
          zIndex: "4",
          opacity: `${activeWard ? 0.7 : 1}`,
        }}
        src={`https://blogerverse.com${user.shoesUrl}`}
        />
    </div>
    <div className={s.rightIcons}>
      <AppstoreOutlined /> 
      <AppstoreOutlined /> 
    </div>
        </div>
  
};

const MainBtn = () => {
  return (
    <div style={{ margin: "0px auto", display: "flex" }}>
      <button className={s.mainBtn} size="medium" style={{ margin: "0 auto" }}>
        Создать видео
      </button>
    </div>
  );
};

export default MainPage;
