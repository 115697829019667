import Footer from "./Components/Footer/Footer";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./Redux/store";
import MainPage from "./Components/Main";
import Updates from "./Components/Main/Updates";
import Wardrobe from "./Components/Main/Wardrobe";
import Profile from "./Components/Main/Profile";
import Header from "./Components/Header";
import { useLaunchParams } from "@telegram-apps/sdk-react";

function App() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  // const tgWebApp = useLaunchParams(); // Инициализация Telegram Web App SDK

  // useEffect(() => {
  //   // Проверяем, что данные пользователя доступны
  //   if (tgWebApp.initDataUnsafe && tgWebApp.initDataUnsafe.user) {
  //     const userId = tgWebApp.initDataUnsafe.user.id; // Получаем ID пользователя
  //     window.alert(tgWebApp.initDataUser) // Получаем ID пользователя

  //     // Выводим ID пользователя в alert
  //     window.alert(`User ID: ${userId}`);
  //   } else {
  //     window.alert('User data is not available');
  //   }
  // }, [tgWebApp]);
  // useEffect(() => {
  //     const fetchData = async () => {
  //         if (localStorage.getItem("access") && localStorage.getItem("access") !== "undefined") {
  //             dispatch({type: LOGIN_SUCCESS});
  //             let config = {
  //                 method: 'GET',
  //                 maxBodyLength: Infinity,
  //                 url: 'https://bricsyoung.com/api/auth/users/me/',
  //                 headers: {
  //                     "Content-Type": "multipart/form-data",
  //                     'Authorization': `Bearer ${localStorage.getItem("access")}`,
  //                 }
  //             };
  //             dispatch(setUserData(config));
  //         }
  //     }
  //     fetchData()
  // }, [isAuthenticated]);

  const initializeTelegramSDK = async () => {
    // try {
    //   // Попытка инициализировать настоящее окружение Telegram
    //   console.log("Инициализация окружения Telegram");
    //   const [miniApp] = init();
    //   await miniApp.ready();
    // } catch (error) {
    //   // В случае ошибки инициализируем фейковое окружение
    //   console.error('Ошибка при инициализации Telegram:', error);
    //   const initDataRaw = new URLSearchParams([
    //     ['user', JSON.stringify({
    //       id: 99281932,
    //       first_name: 'Andrew',
    //       last_name: 'Rogue',
    //       username: 'rogue',
    //       language_code: 'en',
    //       is_premium: true,
    //       allows_write_to_pm: true,
    //     })],
    //     ['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
    //     ['auth_date', '1716922846'],
    //     ['start_param', 'debug'],
    //     ['chat_type', 'sender'],
    //     ['chat_instance', '8428209589180549439'],
    //   ]).toString();
    //   mockTelegramEnv({
    //     themeParams: {
    //       accentTextColor: '#6ab2f2',
    //       bgColor: '#17212b',
    //       buttonColor: '#5288c1',
    //       buttonTextColor: '#ffffff',
    //       destructiveTextColor: '#ec3942',
    //       headerBgColor: '#fcb69f',
    //       hintColor: '#708499',
    //       linkColor: '#6ab3f3',
    //       secondaryBgColor: '#232e3c',
    //       sectionBgColor: '#17212b',
    //       sectionHeaderTextColor: '#6ab3f3',
    //       subtitleTextColor: '#708499',
    //       textColor: '#f5f5f5',
    //     },
    //     initData: initData(initDataRaw),
    //     initDataRaw,
    //     version: '7.2',
    //     platform: 'tdesktop',
    //   });
    //   console.log('Mock Telegram environment initialized');
    // }
  };

  // Инициализация SDK
  initializeTelegramSDK();
  const [activeWard, setActiveWard] = useState(false);
  const [activeProfile, setActiveProfile] = useState(false);
  const [activeUpdates, setactiveUpdates] = useState(false);
  const [loading, setLoading] = useState(true);

  const { lang } = useSelector((state) => state.lang);
  return (
    <div>
      <BrowserRouter>
        <Header />
        <MainPage activeUpdates={activeUpdates} activeWard={activeWard} activeProfile={activeProfile} />
        {activeUpdates && <Updates />}
        {activeWard && <Wardrobe />}
        {activeProfile && <Profile />}
        <Footer
          setActiveWard={setActiveWard}
          activeWard={activeWard}
          activeUpdates={activeUpdates}
          setactiveUpdates={setactiveUpdates}
          activeProfile={activeProfile}
          setActiveProfile={setActiveProfile}
        />
      </BrowserRouter>
    </div>
  );
}

const BricsApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
export default BricsApp;
